/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 28px;

$h1Size: 36px;

$h2Size: 33px;

$h3Size: 30px;

$h4Size: 27px;

$h5Size: 24px;

$h6Size: 21px;

$mainFont: Roboto, sans-serif;

$displayFont: "Roboto Condensed", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #3a4c78;
$secondary: #545e77;
$light: #fff;
$medium: #f1f1f1;
$dark: #262626;
$border: #a7a7a7;
$alert: #e5322d;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	check: "\e905", 
	clock: "\e901", 
	twentyfour: "\e900", 
	map: "\e902", 
	phone: "\e903", 
	pdf: "\f1c1", 
	mail: "\e904", 
	link: "\f08e", 
	arrow-right: "\f054", 
	arrow-left: "\f053", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	xl: (
		width: em(1700px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
);

