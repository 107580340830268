// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Roboto Condensed", 
    $fileName: "roboto-condensed-v19-latin", 
    $weights: ("400", "700"), 
    $types: all
);

@include fontFaceV2(
    $fontName: "Roboto", 
    $fileName: "roboto-v27-latin", 
    $weights: ("400", "700"), 
    $types: all
);