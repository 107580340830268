// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	text-decoration: none;
	transition: 0.3s;

	@include breakpoint($break) {
	color:$dark;
	background: none;	
	padding: rem(0px);
	}

	@include breakpoint($break,max) {
		border-bottom:2px solid rgba($dark,.1);
		padding: rem(20px) rem(30px);
		background: $light;
		color:$dark;
	}
}

%buttonHover {
	// Link Hover
	color: $primary;
	background: none;

	@include breakpoint($break,max) {
		color: $light;
		background-color: $primary;
	}
}

%buttonActive {
	// Link Active
	color: $primary;
	background: none;
	font-weight: 700;

	@include breakpoint($break,max) {
		color: $light;
		background-color: $primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 60px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $dark; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {

				@include breakpoint($break,max) {
					margin-bottom: rem(250px);
				}

				li {
					@include navButtons {
						@include navButton;
					}
					@include has-sub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;


	#navigation {
		position: fixed;
		top:0px;
		z-index: 100;
		background: transparent;
		transition: .3s;

		a.branding {
			display: inline-block;
			position: relative;
			transition: .3s;
			max-width: 500px;	
		}

		a.brandingsub {
			display: inline-block;
			position: relative;
			transition: .3s;
			max-width: 300px;}

		
		
		&.fixed {
			background: white;
			.headerbranding {
				opacity: 0;
				
			}
			.headerbranding2 {
				opacity: 1;
				}

				a.branding {max-width: 350px;}
				a.brandingsub {max-width: 250px;}
		}
		nav {
			ul {
				&.navi {
					// width: 1584px;
					margin:0 auto;
					transition: 0.3s;
					padding:rem(30px) $baseGap;
					max-width: 1584px;

					@include breakpoint(xl) {
						padding:rem(30px) 0;
					}


					li {
						&.has-sub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	box-shadow: 0 0 10px 3px rgba($dark,.1);

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

.naviAddContainer {
	background:$secondary;

	.naviAdd {
		padding: rem(50px) 0;

		@include breakpoint(medium) {
		padding: rem(30px) 0;
		text-align: center;
		}

		@include breakpoint(giant) {
			text-align: right;
			}

		li {
			text-align: center;
			display: inline-block;

			@include breakpoint(medium) {
			margin: 0 rem(10px);	
			}

			@include breakpoint(large) {
			margin: 0 rem(20px);	
			}

			@include breakpoint(medium,max) {
			display: block;
			margin-bottom: 2px;
			}

			a {
				text-decoration: none;
				transition: 0.3s;
				color:$light;

				@include breakpoint(medium,max) {
					padding: rem(15px);
					background: $light;
					display: block;
					color:$dark;
				}

				@include breakpoint(medium) {
				font-size: rem(16px);
				}
				
				@include breakpoint(large) {
				font-size: rem(18px);
				}

				&:hover, &:focus {
					@include breakpoint(medium,max) {
						background: $primary;
						color:$light;					
					}

					color:rgba($light,.5);
				}

				&.active {
					font-weight: 700;
					color:rgba($light,.5);

					@include breakpoint(medium,max) {
						background: $primary;
						color:$light;					
					}

				}
			}
		}
	}
}