#pageWrap {

	@include breakpoint($break,max) {
	margin-top: rem(60px);
	}

	@include breakpoint($break,max) {
	margin-bottom: rem(60px);
	}

}
// HEADER
//////////////////////////////
.topBar {
	background:$primary;

	ul {
		display: flex;
		align-content: center;
		justify-content: space-between;
		padding:rem(15px) 0;
		color:$light;
		font-family: $displayFont;

		@include breakpoint($break,max) {
			display:none;
		}

		li {
			font-weight: 700;
			font-size: rem(16px);
			position: relative;

			i {
				&:before {
					padding-right: 10px;
					font-weight: 400;
				}
			}

			&.linie {

				&:before {
					content: '';
					height: 18px;
					width: 1px;
					background:rgba($light,.4);
					position: absolute;
					top:5px;
					display: block;
				}
			}

			a.phone, span.phone {
				text-decoration: none;
			}
		}
	}
}
.headerbranding {
	margin-bottom: 10px;
	padding: 10px;
	transition: .3s;
}
.headerbranding2 {
	margin-bottom: 10px;
	padding: 10px;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	transition: .3s;
}
.headerbrandingsub {
	margin-bottom: 200px;
	padding: 10px;
}
.naviContainer {
	@include breakpoint($break) {
	background-color: rgba($color: white, $alpha: 0.8);
	box-shadow: 0 0 10px 3px rgba($dark,.1);
	}
	

	.brandingContainer {
		display:none;

		@include breakpoint($break) {
			opacity: 0;
			width: 0;
			transition: 0.3s;
			visibility: hidden;
			display: none;
		}
	}

	.branding {
		@include breakpoint($break) {
		opacity: 0;
		width: 0;
		transition: 0.3s;
		visibility: hidden;
		}
	}


	.naviWidth {
		@include breakpoint($break) {
			max-width: 100%;
			flex-basis: 100%;
		}
	}

	&.top {
		animation: toTop 300ms 1 ease-in;

		@keyframes toTop {
			from { top: 58px;transform: translate(-50%, -100%)}
			to { top: 58px; transform: translate(-50%, 0)}
		}
	}

	
	&.fix {
		@include breakpoint($break) {
			width: 100%;
			background:$light;
			box-shadow: 0 0 10px 2px rgba($dark,.1);
			position: fixed;
			z-index: 100;
			top: 0;
			animation: toBottom 300ms 1 ease-in;
			//transform: translate(-50%, -100%);
			//transition: 0.3s;
			//opacity: 1.0;
		}
		
		@keyframes toBottom {
			from { top: 0;transform: translate(-50%, -100%)}
			to { top: 0; transform: translate(-50%, 0)}
		}
		

		ul.navi {
			@include breakpoint($break) {
			margin: 0 auto;
			max-width: 1150px;
			font-size: 16px;
			}

			@include breakpoint(full) {
				font-size: 18px;
			}
		}
		.brandingContainer {
			@include breakpoint($break) {
			max-width: 25%;
			flex-basis: 25%;
			opacity: 1;
			visibility: visible;
			display: inline-block;
			}
		}

		.branding {
			@include breakpoint($break) {
			opacity: 1;
			visibility: visible;				
			}

			img {
				
				@include breakpoint($break) {
					width: 230px !important;
				}

				@include breakpoint(full) {
					width: 250px !important;
				}
			}
		}

		.naviWidth {
			max-width: 75%;
			flex-basis: 75%;
			padding-left: 0;
		}
	}
}

#header {
	background:url(/images/layout/headerbg.jpg) ;
	position: relative;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 50px;
	text-align: center;
	background-position: -700px -200px;

		@include breakpoint(small) {
			padding-top: 100px;
			background-position: -500px -200px;
		}

		@include breakpoint(medium) {
			padding-top: 150px;
			text-align: left;
			background-position: -200px -100px;
		}

		@include breakpoint(giant) {
			padding-top: 390px;
			text-align: left;
			background-position: 0px -50px;
		}
	
		@include breakpoint(huge,full) {
			padding-top: 390px;
			text-align: left;
			
		}

	&.sub {background:url(/images/layout/headerbg.jpg) ;
		position: relative;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		background-position: 0 -50px;

		@include breakpoint(small) {
			padding-top: 100px;
			background-position: 0px -100px;
		}

		@include breakpoint(medium) {
			padding-top: 150px;
			text-align: left;
			background-position: 0px -100px;
		}

		@include breakpoint(giant) {
			padding-top: 270px;
			text-align: left;
			background-position: 0px -100px;
		}
	
		@include breakpoint(huge,full) {
			padding-top: 370px;
			text-align: left;
			
		}
	
		}

	.headerContainer {

		body.cms-liveeditor-is-opened & {
			display:none;
		}

		.claim {
			

			@include breakpoint(large,max) {
				padding-top:rem(50px);
				padding-bottom:rem(50px);
			}

			body:not(.Start)  &{
				@include breakpoint(large,max) {
					padding:0;
				}
			}

			.branding {
				display: inline-block;
				margin-bottom: rem(25px);

				@include breakpoint(giant) {
					margin-bottom: rem(35px);
				}

				@include breakpoint(large,max) {
				display:none;
				}
			}

			p {
				&.headline {
					color: whitesmoke;
					text-transform: uppercase;
					font-weight: 700;
					font-family: $displayFont;
					font-size: rem(25px);
					line-height: rem(33px);
					margin: 0 0 10px 0; 

					@include breakpoint(tiny) {
						font-size: rem(30px);
						line-height: rem(40px);
					}

					@include breakpoint(medium) {
						font-size: rem(40px);
						line-height: rem(50px);
					}

					@include breakpoint(giant) {
						font-size: rem(50px);
						line-height: rem(60px);
						margin: 0 0 20px 0; 
					}

					span {
						display:block;
						color:whitesmoke;
						font-size: rem(22px);
						line-height: rem(32px);
	
						@include breakpoint(tiny) {
							font-size: rem(25px);
							line-height: rem(35px);
						}
	
						@include breakpoint(medium) {
							font-size: rem(35px);
							line-height: rem(45px);
						}

						@include breakpoint(giant) {
							font-size: rem(45px);
							line-height: rem(55px);
						}
					}
				}

				&.text {
					color:whitesmoke;
					font-size: rem(16px);
					line-height: rem(26px);
					margin: 0 auto 20px;

					@include breakpoint(tiny) {
						font-size: rem(18px);
						line-height: rem(28px);
					}

					@include breakpoint(medium) {
						font-size: rem(20px);
						line-height: rem(28px);
					}

					@include breakpoint(giant) {
						font-size: rem(22px);
						line-height: rem(32px);
						margin: 0 auto 40px;
					}

				}
			}

			.phoneContainer {
				margin-bottom: rem(10px);

				@include breakpoint(tiny) {
				margin-bottom: 0;
				margin-right: rem(10px);
				display:inline-block;
				}


				a.phone, span.phone {
					text-decoration: none;
					color: whitesmoke;
					border:2px solid whitesmoke;
					@extend .btn;
					background:transparent;

					
					@include breakpoint(large) {
					cursor: text;
					}

					&:before {
					@extend .icon;
					@extend .icon-phone;
					color: $border;
					padding-right: 10px;
					display: inline-block;
					font-weight: 400;
					position: relative;
					top:2px;
					}

					&:hover, &:focus, &:visited, &:active {
						background:$light;
						color:$border;
					}
				}
			}

			.emailContainer {

				@include breakpoint(tiny) {
				display:inline-block;
				}

				a.escape {
					text-decoration: none;
					@extend .btn;

					&:before {
					@extend .icon;
					@extend .icon-mail;
					color: $light;
					padding-right: 10px;
					display: inline-block;
					font-weight: 400;
					}					
				}
			}
		}
			
		

		///////////////////////////////////////// CSS /////////////////////////////////////

			.swiper-container {
				padding: 0 ;
				cursor: text;
				z-index: 1;
				
				.swiper-pagination {
					position: absolute;
					text-align: center;
					transition: 300ms opacity;
					z-index: 10;
					left: 20px ;
					width: 43px ;

					@include breakpoint(large) {
						left: rem(35px);
						bottom:rem(20px);
					}

					.swiper-pagination-bullet {
						width: .5rem;
						height: .5rem;
						background: rgba($light,0.5);
						border-radius: 90px;
						opacity: 1;
						margin: 0 4px 0 0
					}

					.swiper-pagination-bullet.swiper-pagination-bullet-active {
						background: $light;
					}
				}

				.swiper-wrapper {
					.swiper-slide {
						display: block;
						padding:rem(150px) 0;

						@include breakpoint(small) {
							padding:rem(200px) 0;
						}

						@include breakpoint(large) {
							padding:rem(250px) 0;
						}

						@include breakpoint(giant) {
							padding:rem(310px) 0;
						}

						body:not(.Start) & {
							@include breakpoint(large,max) {
							display:none;
							}

							@include breakpoint(large) {
								padding:rem(100px) 0;
							}

							@include breakpoint(full) {
								padding:rem(150px) 0;
							}
						}

						&.first {
							@include responsiveImage('layout/slider/elektro1.jpg', (small, large), false);
							background-size: cover;
							background-position: center center;
						}

						&.second {
							@include responsiveImage('layout/slider/elektro2.jpg', (small, large), false);
							background-size: cover;
							background-position: center center;
						}

						&.third {
							@include responsiveImage('layout/slider/elektro3.jpg', (small, large), false);
							background-size: cover;
							background-position: center center;
						}
					}
				}
				
			}
	}

	.benefits {
		background:$medium;

		body:not(.Start) & {
			@include breakpoint(large,max) {
			display:none;
			}
		}

		body.cms-liveeditor-is-opened & {
			display:none;
		}

		ul {
			padding:rem(35px);
			text-align: center;

			@include breakpoint(full) {
				padding:rem(35px) rem(50px);
			}

			@include breakpoint(xl) {
				padding:rem(35px) rem(25px);
			}

			@include breakpoint(small) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			  }

			li { 
				font-weight: 700;
				@include breakpoint(small) {
				  margin:0 rem(10px);          
				}

				@include breakpoint(giant) {
					margin:0 rem(20px);   
				}

				@include breakpoint(huge) {
					font-size: rem(17px);   
				}

				@include breakpoint(full) {
					font-size: rem(19px);  
				}
				
				@include breakpoint(xl) {
					margin:0 rem(30px);   
				}

				&:before {
					@extend .icon;
					color:$secondary;
					padding-right: rem(7px);
					@extend .icon-check;
					font-weight: 400;
					position: relative;
					top:1px;

					@include breakpoint(full) {
						padding-right: rem(10px);
					}
				  }
				
			}
		}
	}

}



.teaserContainer {
	body.cms-liveeditor-is-opened & {
		display:none;
	}

	.teaserSwiper {

		.controller {
			transform: translate(-50%, 0%);
			position: absolute;
			left:50%;
			bottom:0%;
			height: rem(26px);
			z-index: 2;

			@include breakpoint(giant) {
				display: none;
			}

			.swiper-button-next, .swiper-button-prev { 
				display: inline-block;
				background: none;
				width: auto;
				margin: 0;
				height: 22px;
				position: relative;
			
				&:before {
					@extend .icon;
					color: $dark;
					display: inline-block;
					font-size:rem(20px) ;
				}
			}
			
			.swiper-button-next {	
				&:before {
					@extend .icon;
					@extend .icon-arrow-right;
					position: absolute;
					left: 22px;
				}
			}
			

			.swiper-button-prev {
				border-left:1px solid rgba($dark,.3);

				&:before {
					@extend .icon;
					@extend .icon-arrow-right;
					transform: rotateY(180deg);	
					position: absolute;
					right:19px;	
				}
			}
		}

		.swiper-wrapper {
			margin-bottom: rem(80px);

			@include breakpoint(giant) {
				justify-content: center;
				align-items: center;
				margin-bottom: rem(0px);
			}

			.swiper-slide {
				@include breakpoint(giant) {
				width: 25%;
				}
			}
		}
	
		.bg {
			display:block;
			text-decoration: none;
			color:$dark;
			transition: 0.3s;
			height: rem(600px);
			// height: auto;
			position: relative;
			text-align: center;
			background-color:$medium;

			@include breakpoint(tiny) {
				height: rem(510px);
			}

			@include breakpoint(small) {
				height: rem(530px);
			}

			@include breakpoint(medium) {
				height: rem(580px);
			}

			@include breakpoint(large) {
				height: rem(530px);
			}

			@include breakpoint(giant) {
				// height: rem(550px);
				margin: 0 3% ;
				height: 100%;
			}	

			&:hover {
			}

			.textContainer {
				padding:rem(50px) rem(25px) rem(110px);


				p {
					display: block;
					
					&.headline {
						// padding-top:250px;
						text-transform: uppercase;
						color:$secondary;
						font-family: $displayFont;
						font-weight: 700;
						font-size: rem(20px);
						line-height: rem(30px);
						margin-bottom:rem(10px);
	
						@include breakpoint(giant) {
							margin-bottom:rem(20px);
						}
	
						@include breakpoint(full) {
							font-size: rem(22px);
							line-height: rem(32px);
						}
					}	
					
					&.text {
						font-size: rem(16px);
						line-height: rem(26px);
	
						@include breakpoint(full) {
							font-size: rem(18px);
							line-height: rem(28px);
						}
					}
				}

				a.btn {
					position: absolute;
					width: 170px;
					transform: translate(-50%, 0%);
					bottom: 50px;

					@include breakpoint(small) {
						width: 190px;
					}
				}
			}

			

			.first {
				@include responsiveImage('index/teaser/elektroinstallation.jpg', (tiny), true);
				background-position: center center;
				background-size: cover;
				padding:100px 0;
				filter: grayscale(100%);
			}
		 

			.second {
				@include responsiveImage('index/teaser/elektriker.jpg', (tiny), true);
				background-position: center center;
				background-size: cover;
				padding:100px 0;	
				filter: grayscale(100%);
			}

			.third {
				@include responsiveImage('index/teaser/smart-home.jpg', (tiny), true);
				background-position: center center;
				background-size: cover;
				padding:100px 0;
				filter: grayscale(100%);
			}

			.fourth {
				@include responsiveImage('index/teaser/wohnungsbau.jpg', (tiny), true);
				background-position: center center;	
				background-size: cover;	
				padding:100px 0;
				filter: grayscale(100%);
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	.bgContainer {
		// height: 100%;
		background:$medium;
		padding:rem(25px);

		@include breakpoint(giant) {
			padding:rem(50px);
		}
	}

	.imgContent {
		img {

			@include breakpoint(large) {
				margin-bottom: 0;
			}
		}
	}

	.bgImg1 {
		@include responsiveImage('index/elektriker.jpg', (tiny, medium, large, giant), true);
		background-position: center right;
		padding:rem(150px) 0;

		@include breakpoint(small) {
			padding:rem(200px) 0;
		}

		@include breakpoint(large) {
			padding:rem(350px) 0;
		}

		@include breakpoint(giant) {
			padding:rem(250px) 0;
		}

		&.lazyLoaded {
			background-size:cover;
		}
	}

	.bgImg2 {
		@include responsiveImage('index/elektrotechnik.jpg', (tiny, medium, large, giant), true);
		background-position: center left;
		padding:rem(150px) 0;

		@include breakpoint(large,max) {
		margin-bottom: rem(40px);
		}

		@include breakpoint(small) {
			padding:rem(200px) 0;
		}

		@include breakpoint(large) {
			padding:rem(350px) 0;
		}

		@include breakpoint(giant) {
			padding:rem(250px) 0;
		}

		&.lazyLoaded {
			background-size:cover;
		}
	}

	.separator {
		body.cms-liveeditor-is-opened & {
			display:none;
		}

		@include responsiveImage('index/bgSeparator.jpg', (tiny, medium, giant), true);
		padding:rem(100px) 0;
		position: relative;
		filter: grayscale(100%);

		@include breakpoint(giant) {		
			padding:rem(160px) 0;
		}

		&.lazyLoaded {
			background-size:cover;
			background-position: center center;

			@include breakpoint(large) {
				background-attachment: fixed;
			}
		}

		color:$light;
		text-align: center;

		&:before {
			content:'';
			background:rgba($dark,.4);
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
		}

		p {
			&.headline {
				text-transform: uppercase;
				font-weight: 700;
				font-size: rem(30px);
				line-height: rem(40px);
				font-family: $displayFont;

				@include breakpoint(tiny) {
					font-size: rem(35px);
					line-height: rem(45px);
				}

				@include breakpoint(medium) {
					font-size: rem(40px);
					line-height: rem(50px);
				}

				@include breakpoint(giant) {
				font-size: rem(50px);
				line-height: rem(60px);
				}

				@include breakpoint(full) {
				font-size: rem(60px);
				line-height: rem(70px);
				}

				span {
					display:block;
					text-transform: none;
					font-weight: 400;
					font-size: rem(20px);
					line-height: rem(30px);
					font-family: $mainFont;
	
					@include breakpoint(medium) {
						font-size: rem(25px);
						line-height: rem(35px);
					}
	
					@include breakpoint(giant) {
						font-size: rem(30px);
						line-height: rem(40px);
					}
				}
			}
		}

		.btn {
			background:$light;
			color:$dark;

			&:hover {
				background:$primary;
				color:$light;
			}
		}
	}

	.separatorJobs {
		body.cms-liveeditor-is-opened & {
			display:none;
		}
		
		@include responsiveImage('index/bgLux.jpg', (tiny, medium, giant), true);
		padding:rem(100px) 0;
		position: relative;
		filter: grayscale(100%);

		@include breakpoint(giant) {		
			padding:rem(160px) 0;
		}

		&.lazyLoaded {
			background-size:cover;
			background-position: center center;

			@include breakpoint(large) {
				background-attachment: fixed;
			}
		}

		body.stellenangebot & {
			padding:rem(30px) 0;

			@include breakpoint(giant) {		
				padding:rem(75px) 0;
			}
		}

		color:$light;
		text-align: center;

		@include breakpoint(large) {
			text-align: left;
		}

		.lux {
			@include breakpoint(large) {
			float: right;
			margin-bottom: 0 !important;
			}

	
			@include breakpoint(large,max) {
			width: 130px !important;
			margin-bottom: 10px !important;
			}
			
		}

		&:before {
			content:'';
			background:rgba($dark,.7);
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
		}

		
		p {
			&.headline {
				text-transform: uppercase;
				font-weight: 700;
				font-size: rem(30px);
				line-height: rem(40px);
				font-family: $displayFont;
				margin-bottom: 0;

				@include breakpoint(tiny) {
					font-size: rem(40px);
					line-height: rem(50px);
				}

				@include breakpoint(medium) {
					font-size: rem(50px);
					line-height: rem(60px);
				}

				@include breakpoint(giant) {
				font-size: rem(60px);
				line-height: rem(70px);
				}

				@include breakpoint(full) {
				font-size: rem(70px);
				line-height: rem(80px);
				}

				span {
					display:block;
					text-transform: none;
					font-weight: 400;
					font-size: rem(20px);
					line-height: rem(30px);
					font-family: $mainFont;
	
					@include breakpoint(medium) {
						font-size: rem(25px);
						line-height: rem(35px);
					}
	
					@include breakpoint(giant) {
						font-size: rem(30px);
						line-height: rem(40px);
					}
				}
			}
		}

		.btn {
			background:$light;
			color:$dark;
			margin-top: rem(30px);

			&:hover {
				background:$primary;
				color:$light;
			}
		}
	}
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;

		&.last {
			margin-bottom: 0;
		}

		a {
			transition: 0.3s;
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {

	.footerContainer {
		background:$medium;
		padding:rem(50px) 0;

	

		ul {
			padding:rem(15px) 0;
			text-align: center;

			@include breakpoint(giant) {
				display: flex;
				flex-wrap: wrap;
				justify-content:space-between;
				text-align: left;
				align-items: center;
			  }

			li { 
				@include breakpoint(giant) {
				  margin-right: 25px;          
				}

				&.linie {
					height: 50px;
					width: 1px;
					background:rgba($dark,.2);

					@include breakpoint(giant,max) {
					display:none;
					}
				}

				a {
					transition: 0.3s;
				}

				&.socials {
					padding:rem(40px) 0;

					.facebook {
						margin:0 5px;
						display:inline-block;
					}

					a {
						transition: 0.3s;

						&:hover {
							opacity: .7;
						}
					}
				}

				.e-handwerk {

					@include breakpoint(small,max) {
						margin-bottom: rem(25px);
						display: block;
					}

					@include breakpoint(small) {
					margin-right: rem(25px);
					display:inline-block;
					}
				}

				.handwerkring {
					@include breakpoint(small) {
						display:inline-block;
					}
				}

				span {
					&.headline {
						color:$secondary;
						font-weight: 700;
						font-family: $displayFont;
						font-size: rem(22px);
					}
				}

				i {
					&.icon {
						padding-right: 10px;
						color:$border;
					}
					
				}
				
			}
		}
	}
}


//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
.padding {
padding: rem(50px) 0;
@include breakpoint(medium) {
padding: rem(100px) 0;
}
@include breakpoint(giant) {
padding: rem(120px) 0;
}
@include breakpoint(full) {
padding: rem(160px) 0;
}
}
.paddingTop {
padding: rem(50px) 0 0;
@include breakpoint(medium) {
padding: rem(100px) 0 0;
}
@include breakpoint(giant) {
padding: rem(120px) 0 0;
}
@include breakpoint(full) {
padding: rem(160px) 0 0;
}
}
.paddingBottom {
padding: 0 0 rem(50px) 0;
@include breakpoint(medium) {
padding: 0 0 rem(100px) 0;	
}
@include breakpoint(giant) {
padding: 0 0  rem(120px) 0;
}
@include breakpoint(full) {
padding: 0 0  rem(160px) 0;
}
}
.paddingLeft {
@include breakpoint(large) {
padding-left: rem(50px);	
}
@include breakpoint(giant) {
padding-left: rem(75px);
}
}
.paddingRight {
@include breakpoint(large) {
padding-right: rem(50px);	
}
@include breakpoint(giant) {
padding-right: rem(75px);	
}
}
.flexMiddle {
display: flex;
align-items: center;
justify-content: center;
}
.center {
text-align: center;
}
.marginNoBottom {
margin-bottom: 0 !important;
}