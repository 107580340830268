// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: rgba($dark,.5);
		font-weight: 700;
		font-family: $displayFont;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: rgba($dark,.5);
		font-weight: 700;
		font-family: $displayFont;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: rgba($dark,.5) !important;
		font-weight: 700;
		font-family: $displayFont;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: rgba($dark,.5);
		font-weight: 700;
		font-family: $displayFont;
	}

	fieldset {
		border: none;
		margin: 0;
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend, span.legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;
		clear:both;
		font-family: $displayFont;
		font-weight: 700;
		font-size: 20px;
		// color:$secondary;

		&.rueckruf {
			padding-top: 0px;
		}

		&.dse {			
			padding-top: 0px;
			font-size: 14px;
			font-weight: 400;

			a {
				transition: 0.3s;
			}
		}

		&.Name {
			padding-top: 0;
		}

			
		

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;			
		}

		&[for*="privacy-policy"],  &[for*="datenschutz"]{
			// @extend .marginBottom;
			float: right;
			width: calc(100% - #{rem(30px)});
		}
		
		&[for*="rueckrufbitte-ja"], &[for*="callback-yes"] {
			// @extend .marginBottom;
			float: right;
			width: calc(100% - #{rem(30px)});
		}
	}

	input.phone {

		@include breakpoint(large,max) {
			margin-bottom: rem(25px);
		}
	}

	small {
		line-height: 1rem;
		display: inline-block;
		font-size:0;
		
		&.dseAnfahrt {
			padding:rem(15px) 0;
			display: block;
		}

		&:before{
			font-size:1rem;
			padding-left:rem(6px);
		}
		body.lang-en &{
			&:before{
				content:'(Required)';
			}
		}
		body.lang-de &{
			&:before{
				content:'*';
				color:$secondary;
				font-size: 14px;
				position: relative;
				top:-6px;
				left:0px;
			}
		}
	}

	&.cms-map {
		small {
			font-size: rem(12px);

			&:before {
				display: none;
			}
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border-bottom: 2px solid rgba($border,.3);
		border-left: 2px solid rgba($border,.3);
		border-top:none;
		border-right: none;
		// border-bottom-left-radius: 20px;
		display: block;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: rem(18px) rem(25px);
		position: relative;
		resize: none;
		width: 100%;
		color: rgba($dark,.5);
		font-weight: 700;
		font-family: $displayFont;

		&:focus, &:hover {
			border-color:$border;
			color: rgba($dark,.8);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 6.5rem;
		padding-top: 0.9rem;
		margin-bottom: 30px;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 6.5rem * $inputgroup-count - 4rem;
		}
		&[name="Text"] {
			min-height: 6.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		margin: 0 rem(15px) 0 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);
		flex-shrink: 0;

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		margin: 0 rem(15px) 0 0;
        width: rem(15px);
		min-height: auto;
		flex-shrink: 0;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 ;
		width: 100%;
    }

	div[data-field-error] {
		color: red;
		font-weight: 700;
		font-size: rem(18px);
	}
}
div.error {
    background: red;
    color: #fff;
    padding: 1rem;
	max-width: 1584px;
	margin: 0 auto;
	text-align: center;
    margin-top: rem(35px);
}
.contactText{
	border-top:1px solid $border;
	border-bottom:1px solid $border;
	padding:rem(30px) 0;
	margin:rem(30px) 0 rem(40px) 0;
	p{
		margin-bottom:0;
	}
}
/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
